import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import cookie from "js-cookie";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
import "bootstrap-icons/font/bootstrap-icons.css";
import CryptoJS from "crypto-js";

let token = cookie.get("monscrapRefreshToken");
if (token) {
  let userData = cookie.get("userData");
  if (userData) {
    let cookieUser = CryptoJS.AES.decrypt(
      userData,
      process.env.VUE_APP_HASH_KEY
    );
    let value = cookieUser.toString(CryptoJS.enc.Utf8);
    let user = JSON.parse(value);
    store.commit("data/SET_USER", user);
  }
}

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
