import jwtDefaultConfig from "./jwtDefaultConfig";
import cookie from "js-cookie";
import CryptoJS from "crypto-js";
import store from "@/store";
import router from "@/router";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (
            cookie.get("monscrapRefreshToken") &&
            store.getters["data/retryRequest"] <= 5
          ) {
            this.refreshToken()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false;
                this.setToken(r.data.accessToken);
                this.setRefreshToken(r.data.refreshToken);
                this.onAccessTokenFetched(r.data.accessToken);
                store.commit("data/SET_RETRY_REQUEST");
              })
              .catch((err) => {
                console.log(err);
                cookie.remove("monscrapToken");
                cookie.remove("monscrapRefreshToken");
                window.location.href = "/login";
              });
          } else {
            cookie.remove("monscrapToken");
            cookie.remove("monscrapRefreshToken");
            window.location.href = "/login";
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return cookie.get(this.jwtConfig.cookieAccessToken);
  }

  getRefreshToken() {
    return cookie.get(this.jwtConfig.cookieRefreshToken);
  }

  getUserID() {
    let userData = store.getters["data/user"];
    return userData.id;
  }

  setToken(value) {
    cookie.set(this.jwtConfig.cookieAccessToken, value);
  }

  setRefreshToken(value) {
    cookie.set(this.jwtConfig.cookieRefreshToken, value);
  }

  setUserData(value) {
    let user_data = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      process.env.VUE_APP_HASH_KEY
    ).toString();
    cookie.set(this.jwtConfig.cookieUserData, user_data);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      data: {
        refreshToken: this.getRefreshToken(),
        uid: this.getUserID(),
      },
    });
  }
}
