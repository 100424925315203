import cookie from "js-cookie";
import CryptoJS from "crypto-js";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    user: {},
    accessToken: cookie.get("monscrapToken") ? cookie.get("monscrapToken") : "",
    refreshToken: cookie.get("monscrapRefreshToken")
      ? cookie.get("monscrapRefreshToken")
      : "",
    fromDate: cookie.get("fromDate")
      ? cookie.get("fromDate")
      : moment().set({ hour: "00", minute: "00", second: "00" }),
    toDate: cookie.get("toDate")
      ? cookie.get("toDate")
      : moment().set({ hour: "23", minute: "59", second: "59" }),
    retryRequest: 0,
  },
  getters: {
    user: (state) => state.user,
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    fromDate: (state) => state.fromDate,
    toDate: (state) => state.toDate,
    retryRequest: (state) => state.retryRequest,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.accessToken = token;
    },
    SET_FILTER_DATE(state, date) {
      state.fromDate = date.from_date;
      state.toDate = date.to_date;
    },
    SET_RETRY_REQUEST(state) {
      state.retryRequest = state.retryRequest + 1;
    },
  },
  actions: {
    setUser({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          let user_session = CryptoJS.AES.decrypt(process.env.VUE_APP_HASH_KEY);
          let user_data = user_session.toString(CryptoJS.enc.Utf8);
          let user_data_obj = JSON.parse(user_data);
          let accessToken = cookie.get("monscrapToken");

          commit("SET_TOKEN", accessToken);
          commit("SET_USER", user_data_obj);
          resolve(accessToken);
        } catch (e) {
          console.log(e);
          reject();
        }
      });
    },
  },
};
