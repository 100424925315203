import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // dashboard page
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/pages/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Dashboard",
            icon: "bi bi-columns-gap",
            active: true,
          },
        ],
      },
    },

    // online news page
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/online-news/ErrorLogs.vue"),
      meta: {
        pageTitle: "Error",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Error Logs",
            icon: "bi bi-x-circle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/redirected",
      name: "redirected",
      component: () => import("@/views/pages/online-news/RedirectedLogs"),
      meta: {
        pageTitle: "Redirected",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Redirected Logs",
            icon: "bi bi-repeat",
            active: true,
          },
        ],
      },
    },
    {
      path: "/unavailable",
      name: "unavailable",
      component: () => import("@/views/pages/online-news/UnavailableLogs"),
      meta: {
        pageTitle: "Unavailable",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Unavailable Logs",
            icon: "bi bi-slash-circle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/missingclass",
      name: "missingclass",
      component: () => import("@/views/pages/online-news/MissingClassLogs"),
      meta: {
        pageTitle: "Missing Class",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Missing Class Logs",
            icon: "bi bi-exclamation-circle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/detail-logs",
      name: "detail-logs",
      component: () => import("@/views/pages/ScraperDetail.vue"),
      meta: {
        pageTitle: "Detail Scraper",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Detail Scraper",
            icon: "bi bi-journal-text",
            active: true,
          },
        ],
      },
    },
    {
      path: "/no-article",
      name: "no-article",
      component: () => import("@/views/pages/online-news/NoArticleLogs.vue"),
      meta: {
        pageTitle: "No Article",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "No Article",
            icon: "bi bi-file-earmark-x",
            active: true,
          },
        ],
      },
    },
    {
      path: "/no-content",
      name: "no-content",
      component: () => import("@/views/pages/online-news/NoContentLogs.vue"),
      meta: {
        pageTitle: "No Content",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "No Content",
            icon: "bi bi-journal-x",
            active: true,
          },
        ],
      },
    },
    {
      path: "/related-link",
      name: "related-link",
      component: () => import("@/views/pages/online-news/RelatedLinkLogs.vue"),
      meta: {
        pageTitle: "Related Link",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Related Link",
            icon: "bi bi-link-45deg",
            active: true,
          },
        ],
      },
    },
    {
      path: "/invalid-date",
      name: "invalid-date",
      component: () => import("@/views/pages/online-news/InvalidDate.vue"),
      meta: {
        pageTitle: "Invalid Date",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Invalid Date",
            icon: "bi bi-calendar-x",
            active: true,
          },
        ],
      },
    },
    {
      path: "/invalid-date-detail",
      name: "invalid-date-detail",
      component: () =>
        import("@/views/pages/online-news/InvalidDateDetail.vue"),
      meta: {
        pageTitle: "Invalid Date Detail",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Invalid Date Detail",
            icon: "bi bi-calendar-x",
            active: true,
          },
        ],
      },
    },
    {
      path: "/fixing",
      name: "fixing",
      component: () => import("@/views/pages/online-news/Fixing.vue"),
      meta: {
        pageTitle: "Fixing",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Fixing",
            icon: "bi bi-tools",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tiktok-logs",
      name: "tiktok-logs",
      component: () => import("@/views/pages/TiktokLogs.vue"),
      meta: {
        pageTitle: "Tiktok Logs",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Tiktok Logs",
            icon: "bi bi-tiktok",
            active: true,
          },
        ],
      },
    },
    {
      path: "/forum-logs",
      name: "forum-logs",
      component: () => import("@/views/pages/ForumLogs.vue"),
      meta: {
        pageTitle: "Forum Logs",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Forum Logs",
            icon: "bi bi-chat-fill",
            active: true,
          },
        ],
      },
    },
    {
      path: "/media-siputra",
      name: "media-siputra",
      component: () => import("@/views/pages/MediaSiputra.vue"),
      meta: {
        pageTitle: "Siputra",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Media Siputra",
            icon: "bi bi-globe2",
            active: true,
          },
        ],
      },
    },

    // {
    //   path: "/virtual-machine",
    //   name: "virtual-machine",
    //   component: () => import("@/views/pages/VirtualMachine.vue"),
    //   meta: {
    //     pageTitle: "Virtual Machine",
    //     auth: [1, 2],
    //     breadcrumb: [
    //       {
    //         text: "Virtual Machine",
    //         icon: "bi bi-hdd-rack",
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: "/scraping-schedule",
      name: "scraping-schedule",
      component: () => import("@/views/pages/ScrapingSchedule.vue"),
      meta: {
        pageTitle: "Jadwal Scraping",
        auth: [1, 2],
        breadcrumb: [
          {
            text: "Jadwal Scraping",
            icon: "bi bi-hdd-rack",
            active: true,
          },
        ],
      },
    },
    {
      path: "/log-scraper-tier-1",
      name: "log-scraper-tier-1",
      component: () => import("@/views/pages/LogScraperTier1.vue"),
      meta: {
        pageTitle: "Log Scraper Tier 1",
        auth: [1],
        breadcrumb: [
          {
            text: "Log Scraper Tier 1",
            icon: "bi bi-card-list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/log-media-tier",
      name: "log-media-tier",
      component: () => import("@/views/pages/LogMediaTier.vue"),
      meta: {
        pageTitle: "Log Tier Media",
        auth: [1],
        breadcrumb: [
          {
            text: "Log Tier Media",
            icon: "bi bi-card-list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/early-warning-system",
      name: "early-warning-system",
      component: () => import("@/views/pages/EarlyWarningSystem.vue"),
      meta: {
        pageTitle: "EWS Penghentian Scraper",
        auth: [1],
        breadcrumb: [
          {
            text: "EWS Penghentian Scraper",
            icon: "bi bi-exclamation-circle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/scraper",
      name: "scraper",
      component: () => import("@/views/pages/master-data/Scraper.vue"),
      meta: {
        pageTitle: "Scraper Online News",
        auth: [1],
        breadcrumb: [
          {
            text: "Scraper",
            icon: "bi bi-card-list",
            active: true,
          },
        ],
      },
    },
    {
      path: "/grafana",
      name: "grafana",
      component: () => import("@/views/pages/master-data/Grafana.vue"),
      meta: {
        pageTitle: "Grafana",
        auth: [1],
        breadcrumb: [
          {
            text: "Grafana",
            icon: "bi bi-server",
            active: true,
          },
        ],
      },
    },
    {
      path: "/problem-classification",
      name: "problem-classification",
      component: () => import("@/views/pages/master-data/ProblemClassification.vue"),
      meta: {
        pageTitle: "Klasifikasi Masalah",
        auth: [1],
        breadcrumb: [
          {
            text: "Klasifikasi Masalah",
            icon: "bi bi-server",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/pages/master-data/User.vue"),
      meta: {
        pageTitle: "Users",
        auth: [1],
        breadcrumb: [
          {
            text: "Users",
            icon: "bi bi-person-lines-fill",
            active: true,
          },
        ],
      },
    },

    // login page
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/pages/Login.vue"),
      meta: {
        pageTitle: "Login",
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },

    // error page
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/pages/Error404.vue"),
      meta: {
        pageTitle: "Error Not Found",
        layout: "full",
        auth: [1, 2],
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login") {
    const isLoggedIn = isUserLoggedIn();
    if (!isLoggedIn) return next({ name: "login" });

    const canAccess = to.meta.auth.includes(store.getters["data/user"].role);
    if (canAccess == false) {
      return next({ name: "error-404" });
    }
  }

  return next();
});

router.afterEach((to) => {
  document.title = `${to.meta.pageTitle} | MONSCRAP`;
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
